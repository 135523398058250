<template>
    <div ref='menu' class="additional-menu">
        <div v-if="canAddDocument" class="additional-menu__item"  @click="$emit('add-document')">
            <div >Добавить документ</div>
            <div class="additional-menu__item-icon">
                <v-icon>mdi-file-plus-outline</v-icon>
            </div>      
        </div>
        <div v-if="canAddFolder" class="additional-menu__item"  @click="$emit('add-section')">
            <div >Добавить раздел</div>
            <div class="additional-menu__item-icon">
                <v-icon>mdi-folder-plus-outline</v-icon>
            </div>      
        </div>
        <div v-if="canRename" class="additional-menu__item"  @click="$emit('rename');closeTools()">
            <div >Переименовать</div>
            <div class="additional-menu__item-icon">
               <v-icon>mdi-pencil</v-icon>
            </div>      
        </div>
        <div  v-if="canDelete" class="additional-menu__item" @click="$emit('deleteAction');closeTools()">
            <div >Удалить</div>
            <div class="additional-menu__item-icon">
                <v-icon>mdi-delete</v-icon>
            </div>      
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
  components: { },
  name: "Tools",
  props: {
      availableTools: Array,
      item: Object,
      depth: Number,
    //   itemtype: String,
      closeTools : Function
  },
  computed: {
    ...mapGetters(['User', 'buffer']),
    canRename() {
        if (this.availableTools.includes('rename')) {
            if(this.User.is_superuser) {
                return true;
            }
        }
        return false;
    },
    canDelete() {
        if (this.availableTools.includes('delete')){
            if(this.User.is_superuser) {
                return true;
            }
        }
        return false;
    },
    canAddFolder() {
        if (this.availableTools.includes('folder')){
            if(this.depth > 2) {
                return false;
            }
            return true;
        }
        return false;
    },
    canAddDocument() {
        if (this.availableTools.includes('document')){
            return true;
        }
        return false;
    },
  },
    mounted(){
        this.fitTool()
    },
    methods: {
      fitTool() {
            // метод для выравнивания меню, если оно выходит за границы.
            let menu = this.$refs.menu
            let aside = this.$refs.menu.closest('.help__aside')
            let box = menu.getBoundingClientRect();
            let bottom = box.bottom + aside.scrollTop;
            let height = document.documentElement.clientHeight - 75;
            if( bottom > height ) {
                menu.style.top = "auto";
                menu.style.bottom = "0px";
            }
        },
  }
}
</script>

<style lang="scss">
    .additional-menu {
        background-color:#fff;
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 2000;
        min-width: 240px;
        border-radius: 3px;
        border: 1px solid #0A467E;
        // box-shadow: 0em 0em 30px 2px rgb(0 0 0 / 30%);
        padding: 10px;

        &__item {
            border-radius: 3px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            color: #000;
            transition: 0.3s;
            font-weight: normal;
            font-size: 16px;
            &:hover {
                color: #0A467E;
                background-color: #F2F9FC;
                & .additional-menu__item-icon > i {
                    color: #0A467E;
                }
            }

            &-icon {
                margin-left: auto;
            }
        }
    }
</style>
